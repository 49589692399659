'use strict';

/**
 * @ngdoc function
 * @name venDisplayApp.controller:StockchartCtrl
 * @description
 * # StockchartCtrl
 * Controller of the venDisplayApp
 */
angular.module('venDisplayApp').controller('StockchartCtrl', ['$scope', function ($scope, HighchartService, $http) {

    var controller = this;

    controller.initialize = function() {
	alert("hello");
      //  controller.initializeChart($scope.currency);
    };
return;
    var priceHistoryObject = {
        url: 'http://api.ven.vc/0/info',
        method: 'POST',
        data: {
            'jsonrpc': '2.0',
            'method': 'priceHistory',
            'id': 1
        }
    };

    controller.initializeChart = function(currency) {
        priceHistoryObject.data.params = [currency];
        $http(priceHistoryObject).then(function(data) {
            var results = data.data.result;
            results = results.reverse();

            results = results.map(function(result) {
                var dataPoint = {
                    name: result.date,
                    y: result.rate
                };

                return dataPoint;
            });

            controller.newChart(results);
        });
    };

    controller.newChart = function(data) {
        var resultsData = data;
        new HighchartService.Chart({
            chart: {
              renderTo: 'stockchart-' + $scope.currency
            },
            title: {
                text: 'Ven exchange rate'
            },
            xAxis: {
                labels: {
                    formatter: function () {
                        if (resultsData[this.value]) {
                            var date = resultsData[this.value].name.split(' ');
                            return date[1];
                        }
                    }
                }
            },
            rangeSelector: {
              selected: 1
            },
            series: [{
              name: $scope.currency + ' to VEN',
              data: data
            }]
        });
    };

    controller.initialize();

}]);
