var venDisplayApp = angular.module('venDisplayApp', ['StockchartCtrl', 'HighchartService'],function($interpolateProvider) {
			$interpolateProvider.startSymbol("[[");
			$interpolateProvider.endSymbol("]]");
		 });

var mainApp = angular.module("mainApp", ['angular-marquee'], function($interpolateProvider) {
			$interpolateProvider.startSymbol("[[");
			$interpolateProvider.endSymbol("]]");
		 });		 

         mainApp.controller('studentController', function($scope) {
            $scope.student = {
               firstName: "Mahesh",
               lastName: "Parashar",
               
               fullName: function() {
                  var studentObject;
                  studentObject = $scope.student;
                  return studentObject.firstName + " " + studentObject.lastName;
               }
            };
         });
 mainApp.controller('CurrencyTableCtrl', function ($scope, $http) {
    var controller = this;
    $scope.prices = [];

    $scope.factor = 1;

    var listPricesObject = {
        url: window.location.protocol + '//apilaravel.ven.vc/newrpc',
        method: 'POST',
        data: {
            'jsonrpc': '2.0',
            'method': 'listPrices',
            'id': 1
        }
    };

    $http(listPricesObject).then(function(data, status, headers, config) {
        controller.response = {
            status: status,
            headers: headers,
            config: config
        };
        $scope.dataRetrievalTime = new Date();
        $scope.scroll = true;
        $scope.duration = 20000;
        $scope.prices = data.data.result;
    });

});

mainApp.controller('StockchartCtrl', function ($scope, $http) {

    var controller = this;

    controller.initialize = function() {
        controller.initializeChart($scope.currency);
    };

    var priceHistoryObject = {
        url: window.location.protocol + '//apilaravel.ven.vc/newrpc',
        method: 'POST',
        data: {
            'jsonrpc': '2.0',
            'method': 'priceHistoriesSummary',
            'id': 1
        }
    };

    controller.initializeChart = function(currency) {
        priceHistoryObject.data.params = [currency];
        $http(priceHistoryObject).then(function(data) {
            var results = data.data.result;
            results = results.reverse();

            results = results.map(function(result) {
                var dataPoint = [result.date*1000,result.rate];
                return dataPoint;
            });

            controller.newChart(results);
        });
    };

    controller.newChart = function(data) {
        var resultsData = data;
        new  Highcharts.StockChart({
            chart: {
              renderTo: 'stockchart-' + $scope.currency
            },
            title: {
                text: 'Ven Exchange rate'
            },
            xAxis: {
                visible: false
            },
            rangeSelector: {
			   enabled: true, 
               selected: 5
            },
            series: [{
              name: $scope.currency + ' to VEN',
              data: data
            }]
   
        });
    };

    controller.initialize();

});