'use strict';

/**
 * @ngdoc directive
 * @name venDisplayApp.directive:stockchart
 * @description
 * # stockchart
 */
angular.module('venDisplayApp').directive('stockchart', function () {
    return {
        templateUrl: 'views/stockchart.html',
        restrict: 'E',
        controller: 'StockchartCtrl',
        scope: {
            currency: '=currency'
        },
        link: function postLink(scope, element, attrs) {
        //scope.newChart();
        }
    };
});
